import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  row: {
    padding: "30px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  flexRow: {
    flexDirection: "row",
    maxHeight: 400,
  },
  flexRowReverse: {
    flexDirection: "row-reverse",
  },
  flexCol: {
    flexDirection: "column",
  },

  cell: {
    maxWidth: "500px",
    textAlign: "center",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
  },
  textCell: {
    margin: "10px",
  },
  imgCell: {
    padding: "10px",
    width: "50%",
    maxHeigth: "300px",
  },
  img: {
    maxWidth: "95%",
    maxHeight: "400px",
    margin: "10px auto",
  },
  shadow: {
    boxShadow: "#ababab 3px 3px 10px",
  },
}));

const Feature = ({
  name,
  Icon,
  description,
  imgUrl,
  imgShadow = true,
  reverse,
}) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:720px)");
  return (
    <div
      className={clsx(
        classes.row,
        // eslint-disable-next-line no-nested-ternary
        matches
          ? reverse
            ? classes.flexRowReverse
            : classes.flexRow
          : classes.flexCol
      )}
    >
      <div className={classes.cell}>
        {Icon && (
          <div>
            <Icon style={{ fontSize: 40 }} height="40px" />
          </div>
        )}
        <Typography className={classes.textCell} variant="h4">
          {name}
        </Typography>
        <Typography className={classes.textCell} variant="subtitle1">
          {description}
        </Typography>
      </div>
      <div className={classes.cell}>
        <img
          className={clsx(classes.img, imgShadow && classes.shadow)}
          src={imgUrl}
          alt="feature"
        />
      </div>
    </div>
  );
};
export default Feature;
