import React from "react";
import { Typography, Container, makeStyles } from "@material-ui/core";

// import UpdateIcon from "@material-ui/icons/Update";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LockIcon from "@material-ui/icons/Lock";
// import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import DevicesIcon from "@material-ui/icons/Devices";
import { ReactComponent as SplitwiseIcon } from "../assets/splitwise_icon.svg";

import Footer from "../components/Footer";
import Layout from "../components/Layout";
import Feature from "../old_pages/Landing/Feature";
import CallToAction from "../old_pages/Landing/CallToAction";
import ActionButton from "../old_pages/Landing/ActionButton";

import thumbnailCreate from "../assets/create-min-min.png";
import thumbnailPlaid from "../assets/plaid-min-min.png";
// import thumbnailRecurring from "../assets/recurring-min.png";
import thumbnailSetting from "../assets/settings-min-min.png";
import thumbnailTransactions from "../assets/transactions-min-min.png";
import thumbnailCombined from "../assets/combined_mockup.png";

const features = [
  {
    name: "View all transactions in one place",
    Icon: ReceiptIcon, // should figure out how to just past the component but not the elemnt
    description: (
      <>
        Pulls all of your transactions into one place so you can settle up on{" "}
        <a href="https://splitwise.com" target="_new">
          Splitwise
        </a>{" "}
        &{" "}
        <a href="https://splitwise.com" target="_new">
          Venmo
        </a>{" "}
        (more options on the way). No need to save receipts or worry about
        settling up in the moment.
      </>
    ),
    imgUrl: thumbnailTransactions,
  },
  {
    name: "Split with friends or groups ",
    Icon: SplitwiseIcon,
    description: (
      <>
        Split with your friends or groups on{" "}
        <a href="https://splitwise.com" target="_new">
          Splitwise
        </a>
        .
      </>
    ),
    imgUrl: thumbnailCreate,
  },
  {
    name: "Use on desktop or mobile",
    Icon: DevicesIcon, // should figure out how to just past the component but not the elemnt
    description:
      "Responsively designed web app that works well on both desktop and mobile.",
    imgUrl: thumbnailCombined,
    imgShadow: false,
  },
  // {
  //   name: "Recurring transactions",
  //   Icon: UpdateIcon,
  //   description: (
  //     <>
  //       Add recurring transactions to automatically split the same way every
  //       time. Great for household expenses like utilities and rent. Never forget
  //       to charge your roommates for the water bill again!
  //     </>
  //   ),
  //   imgUrl: thumbnailRecurring,
  // },
  // {
  //   name: "Pending transactions",
  //   Icon: HourglassEmptyIcon,
  //   description:
  //     "Automatically update expeneses for pending transactions once the final amount is settled.",
  //   imgUrl: thumbnailTransactions,
  // },
  {
    name: "Powered by Plaid",
    Icon: LockIcon,
    description: (
      <>
        Reconcile links securely to your accounts using the same technology that
        leading fintech companies (such as Venmo, Mint and Robinhood) use. Your
        credentials are never made available to Reconcile. Learn more{" "}
        <a href="https://plaid.com/security/" target="_new">
          here
        </a>
        .
      </>
    ),
    imgUrl: thumbnailPlaid,
  },
  {
    name: "Add multiple accounts",
    Icon: AccountBalanceIcon,
    description:
      "Link up to 5 different institutions to view all of your transactions in one place.",
    imgUrl: thumbnailSetting,
  },
];

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "center",
  },
  pageHeader: {
    backgroundColor: "#4960a61f",
    paddingBottom: 36,
  },
  tagline: {
    paddingTop: "130px",
    fontWeight: "bold",
  },
  hero: {
    marginTop: "61px",
    width: "100%",
  },
  logo: {
    maxWidth: "180px",
  },
  featuresContainer: {
    maxWidth: "1000px",
  },
  footnotes: {
    padding: "40px",
  },
}));

export default function LandingPage() {
  const classes = useStyles();
  return (
    <Layout topNavPosition="static" showAction noBottomNav maxWidth={false}>
      <div className={classes.pageHeader}>
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h3" gutterBottom className={classes.tagline}>
            Settle Up Smarter
          </Typography>
          <Typography variant="h6" gutterBottom>
            Save time and never forget to settle up with your friends.
          </Typography>
          <ActionButton color style={{ marginBottom: 12 }} />
          <Typography variant="body1" gutterBottom>
            Try it credit card free for 14 days.
          </Typography>
        </Container>
      </div>
      <Container maxWidth="lg" className={classes.featuresContainer}>
        {features.map((f, index) => (
          <Feature key={f.name} {...f} reverse={index % 2 !== 0} />
        ))}
      </Container>
      <CallToAction />
      <Footer />
    </Layout>
  );
}
