import React from "react";

import { makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";

import ActionButton from "./ActionButton";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "50px 0",
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    color: "white",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexCol: {
    flexDirection: "column",
  },
  buttonContainer: {
    padding: "50px",
    textAlign: "center",
  },
  textContainer: {
    textAlign: "center",
  },
  textCell: {
    margin: "10px",
  },
}));

const CallToAction = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:720px)");

  return (
    <div className={classes.root}>
      <Container
        maxWidth="lg"
        className={clsx(
          classes.container,
          matches ? classes.flexRow : classes.flexCol
        )}
      >
        <div className={classes.textContainer}>
          <Typography className={classes.textCell} variant="h4">
            Try for free
          </Typography>
          <Typography className={classes.textCell} variant="subtitle1">
            $4 per month after 14 day trial.
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <ActionButton />
        </div>
      </Container>
    </div>
  );
};

export default CallToAction;
