import React from "react";

import { Link } from "gatsby";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import logo from "../assets/icon.png";

import {
  TERMS_LINK,
  PRIVACY_POLICY_LINK,
  FEEDBACK_LINK,
  CONTACT_LINK,
} from "../constants";

const useStyles = makeStyles(theme => ({
  root: { margin: "100px 0 50px" },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  flexCol: {
    flexDirection: "column",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "16px",
  },
  logo: {
    maxWidth: "200px",
    marginBottom: "50px",
  },
  full: {
    width: "96%",
  },
  half: {
    width: "42%",
  },
  quarter: {
    width: "21%",
  },
  listHeader: {
    fontWeight: "bold",
    color: "black",
  },
  copyright: {
    marginTop: "90px",
    textAlign: "center",
  },
}));
const Footer = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:720px)");

  return (
    <div className={classes.root}>
      <Container
        className={clsx(classes.container, matches ? null : classes.flexCol)}
        maxWidth="lg"
      >
        <div
          className={clsx(
            classes.logoContainer,
            matches ? classes.half : classes.full
          )}
        >
          <div>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
          <Typography variant="caption">
            A software created to help reconcile your electronic transactions.
          </Typography>
        </div>
        <List
          className={clsx(matches ? classes.quarter : classes.full)}
          subheader={
            <ListSubheader className={classes.listHeader}>
              Reconcile
            </ListSubheader>
          }
        >
          <ListItem
            button
            component="a"
            href="https://gabeoleary.com"
            target="_new"
          >
            Created by Gabe O'Leary
          </ListItem>
          <ListItem button component="a" target="_new" href={FEEDBACK_LINK}>
            Feedback
          </ListItem>
        </List>
        <List
          className={clsx(matches ? classes.quarter : classes.full)}
          subheader={
            <ListSubheader className={classes.listHeader}>
              Support
            </ListSubheader>
          }
        >
          <ListItem button component="a" target="_new" href={CONTACT_LINK}>
            Contact
          </ListItem>
          <ListItem button component={Link} to={PRIVACY_POLICY_LINK}>
            Privacy
          </ListItem>
          <ListItem button component={Link} to={TERMS_LINK}>
            Terms of Use
          </ListItem>
        </List>
      </Container>
      <div className={classes.copyright}>
        <Typography variant="body2">
          Copyright {new Date().getFullYear()}, {` `}{" "}
          <Link to="/">Reconcile</Link>. All rights reserved.
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
